#content {
    text-align: center;
}

/* Timeline */
.timeline {
    border-left: 4px solid darkgray;
    margin: 50px auto;
    line-height: 1.4em;
    padding: 50px;
    list-style: none;
    text-align: left;
    font-weight: 100;
    max-width: 400px;
}

.timeline h3 {
    font-weight: 400;
    font-size: 1.4em;
}

.timeline a {
    color: black;
    font-weight: 100;
    font-family: "Montserrat", sans-serif;
    font-size: 1em;
}

.timeline .event {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    padding-bottom: 25px;
    margin-bottom: 50px;
    position: relative;
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .event:before {
    left: -217.5px;
    color: darkgrey;
    content: attr(data-date);
    text-align: right;
    font-weight: 100;
    font-size: 0.9em;
    min-width: 120px;
}

.timeline .event:after {
    box-shadow: 0 0 0 4px #00f2e7;
    left: -57.85px;
    background: white;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
    top: 5px;
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .timeline .event:before {
        display: none;
        left: -217.5px;
        color: darkgrey;
        content: attr(data-date);
        text-align: right;
        font-weight: 100;
        font-size: 0.9em;
        min-width: 120px;
    }
}