.App {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: calc(112.5% + 0.25vw);
}

/* NAV-BAR */

.NavBar {
    position: fixed;
    top: 0;
    width: 100%;
    /* overlay all other elements */
    z-index: 1;
}

.NavBarList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f1f1f1;
}

.nav-link {
    float: right;
    display: block;
    color: grey;
    padding: 14px 16px;
    text-decoration: none;
    font-weight: 300;
}

.nav-link:hover {
    background-color: #00f2e7;
    color: white;
}

/* WELCOME */

.Welcome {
    width : 100%;
    margin-top: 15vh;
    margin-bottom: 15vh;
    font-size: 1.5em;
    justify-content: center;
    -webkit-align-items: center; 
    align-items: center; 
    display: flex;
    float: left;
}

.Intro-Text {
    padding: 0vw 12vw 0vw 12vw;
}

.welcome-intro strong {
    font-weight: 900;
}

.welcome-intro {
    font-weight: 100;
    font-size: 1em;
}


.Welcome .i-hov {
    color: black;
    margin: 10px;
    display: inline-block;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.main-pic {
    border-radius: 50%;
    width: 28vw;
    padding: 20px;
    float: none;
}

.Welcome .resume-button {
    font-size: 18px;
    width: 200px;
}

.Welcome .i-hov:hover {
    color: #00f2e7;
    display: inline-block;
}

.Welcome button {
    font-family: "Montserrat", sans-serif;
    background-color: white;
    color: black;
    border: 2px solid black;
    border-radius: 3px;
    padding: 15px 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 10px 10px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
}

.Welcome button:hover {
    background-color: #00f2e7;
    border: 2px solid;
    border-color: #00f2e7;
    color: white;
}

/* Section Titles */

h2 {
    font-weight: 100;
    font-size: 2em;
    padding-top: 10vh;
}

p {
    font-size: 0.8em;
}

/* Tagcloud */
.tag-cloud {
    max-width: 700px;
    margin: 0 auto;
    cursor: pointer;
}

.projectDetails {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1em;
    /* cursor: pointer; */
}

a {
    font-size: 0.8em;
    margin-top:0;
}

@media only screen and (max-width: 768px) {
    .Welcome {
        display: block;
        margin-top: 8vh;
        margin-bottom: 8vh;
    }
    .main-pic {
        width:50vw;
    }
}